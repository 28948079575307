
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { ValidationRules } from '../models/validationRules';
import { UserServiceClient } from '@/api/clients/userServiceClient';
import { StoresServiceClient } from '@/api/clients/storesServiceClient';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import { RegisterRequestParams } from '@/models/users/registerRequestParams';
import { BrandName } from '@/models/stores/brands';
import { RegistrationStore } from '@/api/contracts/stores/storesByStateResponse';

const userServiceClient = new UserServiceClient();
const storesServiceClient = new StoresServiceClient();

@Component({
  components: {
    VueHcaptcha
  }
})
export default class Register extends Vue {
  public registerRequestParams: RegisterRequestParams = new RegisterRequestParams();
  public rules: ValidationRules = new ValidationRules();
  public states: string[] = [
    'ACT',
    'NSW',
    'NT',
    'QLD',
    'SA',
    'TAS',
    'VIC',
    'WA'
  ];
  public brands: string[] = Object.values(BrandName).filter(
    b => b !== BrandName.SUPA // remove supa for now
  );
  public selectedState: string = '';
  public selectedBrand: string = this.brands[0];
  public storesList: Array<RegistrationStore> = [];
  public selectedStore: RegistrationStore | null = null;
  public isSubmitting: boolean = false;

  // Notification
  public showNotification: boolean = false;
  public notificationMessage: string = '';
  public notificationColour: string = 'green';
  public notificationTimeout: string = '2500';

  @Ref() readonly hcaptchaEl!: VueHcaptcha;
  public hcaptchaSitekey: string = process.env.VUE_APP_HCAPTCHA_SITEKEY;

  public async captchaVerified(token: string) {
    try {
      this.registerRequestParams.captchaToken = token;
      this.registerRequestParams.storeId = this.selectedStore
        ? this.selectedStore.id
        : '';

      await userServiceClient.registerUser(this.registerRequestParams);

      this.notificationMessage =
        'Your registration request has been sent. You will receive an email...';
      this.showNotification = true;
    } catch (err) {
      this.notificationMessage =
        'There was an error sending your registration.';
      this.notificationTimeout = '10000';
      this.notificationColour = 'error';
      this.showNotification = true;
      this.isSubmitting = false;
    }
  }

  public formRegisterSubmit(form: VForm) {
    this.resetNotification();
    this.isSubmitting = true;
    if (form.validate()) {
      // Run the captcha. Response will be handled by the `captchaVerified` callback.
      this.hcaptchaEl.execute();
    }
  }

  public resetNotification() {
    this.notificationTimeout = '2500';
    this.showNotification = false;
    this.notificationColour = 'success';
  }

  public onSelectedStateChange() {
    this.getStoresList();
  }

  public async getStoresList() {
    await storesServiceClient
      .getStoresByState(this.selectedState)
      .then(response => {
        this.storesList = response.data;
      });
  }

  public get filteredStoresListBySelectedBrand(): RegistrationStore[] {
    return this.storesList.filter(store => store.brand === this.selectedBrand);
  }

  public get isSingleStore() {
    return this.filteredStoresListBySelectedBrand.length === 1;
  }

  public get brandFileName(): string {
    switch (this.selectedBrand) {
      case BrandName.LOCAL:
        return 'local-grocer-logo-white@2x.png';
      case BrandName.SUPA:
        return 'supa-valu-logo@2x.png';
      default:
        return 'iga-logo.svg';
    }
  }

  public get logoClass(): string {
    return `rego-${this.brandFileName.split('-')[0]}`;
  }

  @Watch('filteredStoresListBySelectedBrand')
  onFilteredStoresChange(storesList: RegistrationStore[]) {
    if (this.isSingleStore) {
      this.selectedStore = this.filteredStoresListBySelectedBrand[0];
    } else {
      this.selectedStore = null;
    }
  }
}
